import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/styles'


const CTAButton = styled(Button)(({ theme }) => ({
  padding: '.5em 1.5em',
  borderRadius: '2rem',
  fontSize: '1.3rem',
  color: theme.palette.grey[100],
  fontWeight: 800,
  marginRight: 30,
  textTransform: 'initial',
  backgroundColor: theme.palette.secondary.main,
  '&:disabled': {
    backgroundColor: theme.palette.grey[200],
    color: 'white',
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
    margin: 'auto',
  },
}))

const StyledCustomization = props => <CTAButton variant='contained' {...props} />

export default StyledCustomization
