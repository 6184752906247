import React from 'react'
import Page from '../_library/Page'
import NewUserForm from './NewUserForm'
import { Box, Grid, Typography } from '@mui/material'
import BackButton from '../_library/BackButton'


const NewUserPage = () => (
  <Page title='Nouvel utilisateur'>
    <Grid container sx={{
      backgroundColor: 'white',
      position: 'relative',
      minHeight: 300,
      borderRadius: 22,
      p: 5,
      boxShadow: theme => `0 0 10px ${theme.palette.grey[200]}`,
    }}>
      <BackButton sx={{ position: 'absolute', right: 40, top: -20 }} />
      <Grid item md={8} sx={{ borderRight: theme => `solid 2px ${theme.palette.grey[150]}` }}>
        <NewUserForm />
      </Grid>
      <Grid item md={4}>
        <Typography variant='body1' textAlign='center' fontSize='.9rem'>
          Status: <Box component='strong' sx={{  color: 'grey.500', fontSize: '1rem' }}>Pending</Box>
        </Typography>
      </Grid>
    </Grid>
  </Page>
)

export default NewUserPage
