import { mapValues } from 'lodash'
import { arrayUnion, collection, doc, orderBy, query, where, limit } from 'firebase/firestore'
import { db } from '../firebase'


export const hydrateRefs = (db, data) =>
  mapValues(data, (val, index) => {
    if (index.includes('Refs') && val instanceof Array) {
      const strategy = val[2] || 'replace'
      if (strategy === 'add')
        return arrayUnion(doc(db, val[0], val[1]))
      else
        return val[1].map(id => doc(db, val[0], id))
    }
    if (index.includes('Ref') && val instanceof Array) return val[1] ? doc(db, val[0], val[1]) : null
    else return val
  })

export const serialize = doc => mapValues(doc, (val, key) => {
  if (key.endsWith('Date') || key.endsWith('Time')) return val ? val.toDate().toString() : null
  else if (key.endsWith('Refs')) {
    if (!val) return null
    else if (!val.length) return []
    else return [val[0].parent.id, ...val.map(ref => ref.id)]
  }
  else if (key.endsWith('Ref')) return val ? [val.parent.id, val.id] : null
  else return val
})

export const deSerialize = obj => mapValues(obj, (val, key) => {
  if (key.endsWith('Date') || key.endsWith('Time')) return val ? new Date(val) : null
  else if (key.endsWith('Refs')) return val ? val.slice(1).map(id => doc(db, val[0], id)) : null
  else if (key.endsWith('Ref')) return val ? doc(db, ...val) : null
  else return val
})

const getCollectionRef = path => collection(db, path)

export const getQuery = (collection, options) => {
  let compoundQuery = query(getCollectionRef(collection))
  
  if (options?.where) {
    for (let i = 0; i < options.where.length; i++) {
      compoundQuery = query(compoundQuery, where(...options.where[i]))
    }
  }
  
  if (options?.orderBy) {
    for (let i = 0; i < options.orderBy.length; i++) {
      compoundQuery = query(compoundQuery, orderBy(...options.orderBy[i]))
    }
  }
  
  if (options?.limit) {
    compoundQuery = query(compoundQuery, limit(options.limit))
  }
  
  return compoundQuery
}
